<template>
  <div class="relative" id="typeSwitchWrapper">
    <div class="mb-5 pr-3 pb-3 pt-4" v-if="ready">
      <div
        @click="isOpen = !isOpen"
        style="background-color: var(--gc-typeSwitch-background)"
        class="p-2 flex flex-wrap justify-content-between border-radius--8 cursor-pointer"
      >
        <TypeInit :activeType="activeTypeValue" :isOpen="isOpen" />
      </div>
      <TypeOverlay
        :types="types"
        v-if="types.length && isOpen"
        v-model="activeTypeValue"
        @close="isOpen = false"
      />
    </div>
  </div>
</template>

<script>
import menuTypes from "@data/menuTypes";
import TypeOverlay from "./partials/TypeOverlay.vue";
import TypeInit from "./partials/TypeInit.vue";
import { find } from "lodash";

export default {
  name: "TypeSwitch",

  components: {
    TypeOverlay,
    TypeInit,
  },

  data() {
    return {
      isOpen: false,
      ready: false,
      activeTypeValue: null,
    };
  },

  props: {
    activeType: {
      type: Array,
      required: true,
    },
  },

  computed: {
    types() {
      return menuTypes.list().filter(({ switchable }) => switchable === true);
    },
  },

  watch: {
    activeTypeValue(type) {
      if (type) {
        this.$emit("switch", [type.value]);
      }
    },

    activeType() {
      this.loadActiveType();
    },
  },

  methods: {
    clickOutside() {
      const element = document.getElementById("typeSwitchWrapper");
      window.addEventListener("click", (event) => {
        const isClickInsideElement = element.contains(event.target);

        if (this.isOpen && !isClickInsideElement) {
          this.isOpen = false;
        }
      });
    },

    loadActiveType() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          this.activeTypeValue = find(this.types, { value: this.activeType[0] });
          resolve();
        });
      });
    },
  },

  async created() {
    await this.loadActiveType();
    this.ready = true;
  },

  mounted() {
    this.clickOutside();
  },
};
</script>
