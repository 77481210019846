<template>
  <div class="typeSwitcher__appLogo overflow-hidden shadow-1 border-radius--8">
    <img :src="logo" class="w-full h-auto" />
  </div>
</template>

<script>
import logo from "../../../../../../assets/polargold-logo.jpg";

export default {
  name: "AppLogo",

  computed: {
    logo() {
      return logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.typeSwitcher__appLogo {
  height: 56px;
  width: 56px;
  background-color: var(--gc-typeSwitch-logo-background);
}
</style>
