<template>
  <div class="min-h-screen flex-grow-1 flex py-4 pr-4">
    <div class="border-radius--24 py-4 px-6 bg-white flex-grow-1 flex min-h-full">
      <div class="block w-full">
        <div class="mainContent__content">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
};
</script>
