<template>
  <div class="flex flex-wrap justify-content-between align-content-center w-full">
    <div class="flex-shrink-0">
      <AppLogo />
    </div>

    <div class="align-self-center" style="width: 120px">
      <div>
        <BaseText>
          <span class="font-bold text-white">
            {{ $t("shared.navigation.typeSwitcher.title") }}
          </span>
        </BaseText>
      </div>
      <div v-if="activeType">
        <BaseText size="sm">
          <div class="text-white overflow-hidden" style="text-overflow: ellipsis">
            {{ activeType.name }}
          </div>
        </BaseText>
      </div>
    </div>

    <div v-if="activeType" class="align-self-center flex-shrink-0 mr-2">
      <BaseIcon
        :icon="!isOpen ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"
        color="gc-color-white"
      />
    </div>
  </div>
</template>

<script>
import AppLogo from "./AppLogo.vue";

export default {
  name: "TypeInit",

  components: {
    AppLogo,
  },

  props: {
    activeType: {
      type: Object,
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
