<template>
  <div class="flex-none flex w-17rem text-white">
    <div class="block w-full">
      <div class="ml-3 mt-4 mb-4">
        <slot name="header" />
      </div>

      <div class="ml-3 mt-4 mb-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarLeft",
};
</script>
