<template>
  <BaseFrontdoor>
    <ModuleHome />
  </BaseFrontdoor>
</template>

<script>
import ModuleHome from "@shared/ModuleHome";

export default {
  name: "usm",

  components: {
    ModuleHome,
  },
};
</script>
