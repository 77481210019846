<template>
  <div>
    <TypeSwitch @switch="changeMenuTypes($event)" :activeType="types" />
    <div
      v-for="({ label, to, isActive, icon }, index) of items"
      @click="goToPage(to, isActive)"
      :key="index"
    >
      <NavigationItem :label="label" :icon="icon" :isActive="isActive" />
    </div>
  </div>
</template>

<script>
import NavigationItem from "./partials/NavigationItem.vue";
import useMenuItems from "@use/useMenuItems";
import TypeSwitch from "./partials/TypeSwitch";
import menuName from "@data/menuNames";
import menuType from "@data/menuTypes";

export default {
  name: "Navigation",

  menuTypes: {
    type: Array,
    required: false,
    default: null,
  },

  components: {
    NavigationItem,
    TypeSwitch,
  },

  setup() {
    const { items, changeMenuTypes, types } = useMenuItems({
      menuName: menuName.get().mainNavigation,
      menuTypes: [menuType.get().common],
    });

    return { items, changeMenuTypes, types };
  },

  methods: {
    goToPage(path, isActive) {
      if (isActive) return;
      this.$router.push({ path });
    },
  },
};
</script>
