<template>
  <div class="absolute w-full pr-3 mt-2" style="left: 0">
    <div class="shadow-2 border-radius--8 overflow-hidden bg-white py-2">
      <div v-for="(type, index) of types" :key="index">
        <TypeItem
          :type="type"
          :isActive="type.value === activeType.value"
          @switch="switchType($event)"
          @click="$emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TypeItem from "./TypeItem.vue";

export default {
  name: "TypeOverlay",

  components: {
    TypeItem,
  },

  props: {
    types: {
      type: Array,
      required: true,
    },

    modelValue: {
      type: Object,
      required: true,
    },
  },

  computed: {
    activeType: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },

  methods: {
    switchType(type) {
      this.activeType = type;
    },
  },
};
</script>
