<template>
  <BaseHover v-model="hover">
    <div
      @click="switchType"
      class="w-full h-full cursor-pointer flex flex-wrap justify-content-between pr-3 border-left-3"
      :class="[
        { 'border-primary': isActive },
        { 'border-white': !isActive && !hover },
        { 'border-gray-200': hover && !isActive },
        hover || isActive ? 'bg-gray-200' : 'bg-white',
      ]"
    >
      <div
        class="py-3 pl-3 text-sm text-700 align-self-center border-left-1"
        style="width: 170px"
        :class="[
          { 'border-primary': isActive },
          { 'border-white': !isActive && !hover },
          { 'border-gray-200': hover && !isActive },
        ]"
      >
        {{ type.name }}
      </div>
    </div>
  </BaseHover>
</template>

<script>
export default {
  name: "TypeItem",

  props: {
    type: {
      type: Object,
      required: true,
    },

    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  methods: {
    switchType() {
      this.$emit("switch", this.type);
    },
  },
};
</script>
